<script setup lang="ts">
import type { NavButtonProps } from '~/utils/nav'
import { NuxtLink } from '#components'

const props = defineProps<NavButtonProps>()

const { theme } = useTheme()

const route = useRoute()

const hrefBase = computed(() => {
  return typeof props.href === 'string' && props.href
    ? (props.href.endsWith('/') ? props.href : `${props.href}/`)
    : ''
})

const isActive = computed(() => {
  if (hrefBase.value && route.path.startsWith(hrefBase.value)) {
    return true
  }

  if (props.href) {
    return route.path.startsWith(hrefBase.value)
  }

  return false
})
</script>

<template>
  <component :is="href ? NuxtLink : 'button'" :class="[navItemVariants.button.base({ theme, open, badge: !!badge }), isActive ? 'active' : '']" :data-dd-privacy="privacy" :data-theme="theme" :to="href" exact-active-class="active" @click="onClick">
    <IconWithBadge v-if="icon" :icon="icon" :badge="badge" />
    {{ label }}
    <slot />
  </component>
</template>

<style scoped lang="postcss">
[data-theme='blurple'].active {
  @apply bg-blurple-50 dark:bg-blurple-100 dark:text-primary-on_dark;
}

[data-theme='moss'].active {
  @apply bg-moss-200 dark:bg-moss-400 dark:text-secondary-on_dark;
}

[data-theme='polar'].active {
  @apply bg-polar-100 dark:bg-polar-400 dark:text-tertiary-on_dark;
}

[data-theme='carbon'].active {
  @apply bg-white dark:bg-carbon-400 dark:text-inverse;
}
</style>
