import type { IconDefinition } from '@fortawesome/fontawesome-common-types'

export interface Menu {
  value: MenuOption
  label: string
  expandable: boolean
  href?: string
  icon?: IconDefinition
  hide?: boolean
  onClick?: (props?: any) => any
  privacy?: Privacy
}

export function useHeaderState(props: Partial<HeaderProps>, ref: Ref<HTMLElement | null>, isOpen: Ref<boolean>) {
  const headerHeight = useHeaderHeight(ref)
  const scrollState = useScrollState(200)

  return {
    ...scrollState,
    links: useLinks(props),

    // The mobile menu is shown below the header, so we want it to fill the entire screen height _except_ for the height of the header.
    mobileMenuHeight: computed(() => isOpen.value ? `calc(100dvh - ${headerHeight.value})` : '0'),

    // When the menu is open, this ensures that the body is not scrollable behind the menu.
    bodyAttrs: computed(() => isOpen.value ? { class: 'overflow-hidden' } : {}),
  }
}

/**
 * Hide the header when scrolling past a certain threshold, but show it immediately if scrolling up.
 */
function useScrollState(threshold: MaybeRef<number>) {
  const scrollDirection = ref<'down' | 'up'>('up')

  const { y } = useWindowScroll()

  watch(y, (newValue, oldValue) => {
    scrollDirection.value = newValue > oldValue ? 'down' : 'up'
  })

  const hasScrolled = computed(() => y.value > unref(threshold))

  return {
    hasScrolled,
    translateY: computed(() => hasScrolled.value && scrollDirection.value === 'down' ? '-100%' : undefined),
  }
}

/**
 * In the mobile menu, we don't want to show interactive items. Also, we don't separate between left and right.
 *
 * This composable will merge the items, and remove interactive items.
 */
function useLinks(props: Partial<HeaderProps>) {
  return computed(() => [
    ...(props.leftNavItems ?? []).map(it => ({ ...it, type: 'link' })),
    ...(props.rightNavItems ?? []),
  ].filter(it => it.type === 'link') as NavLinkProps[])
}
